import Header from 'components/shared/Header';
import styles from 'styles/landingPage.module.css';
import { Grid } from '@mui/material';
import Head from 'next/head';
import cx from 'classnames';

export default function LandingPage() {

  const appStoreLink = 'https://apps.apple.com/in/app/windo-seller/id1559110127';
  const playStoreLink = 'https://play.google.com/store/apps/details?id=live.windo.seller';
  return (
    <div className={styles.container}>
      <Head>
        <title>Windo Shop</title>
      </Head>
      <Header
        headerStyles={styles.headerStyles}
        showShop={true}
        isLandingPage={true}
      />
      <Grid container spacing={0} className={styles.grid}>
        <Grid item xs={12} md={6} className={cx(styles.firstGrid, 'desktop')}>
          <div className={styles.content}>
            <div className="textCenter">
              create a beautiful online store in just
              <span className={styles.orange}>2-minutes</span>
            </div>
            <div className={styles.icons}>
              <a href={appStoreLink} target="_blank" rel="noreferrer">
                <img src="/images/appstore.svg" alt="" />
              </a>
              <div className={styles.spacer}></div>
              <a href={playStoreLink} target="_blank" rel="noreferrer">
                <img src="/images/playstore.svg" alt="" />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={styles.secondGrid}>
          <div className={cx(styles.mobileText, 'mobile')}>
            create a beautiful online store in just
            <span className={styles.orange}>2-minutes</span>
          </div>
          <img src="/images/weAreOpen.svg" alt="" />
          <div className={cx(styles.icons, 'mobile', styles.mob)}>
            <a href={appStoreLink} target="_blank" rel="noreferrer">
              <img src="/images/appStoreMobile.svg" alt="" />
            </a>
            <a href={playStoreLink} target="_blank" rel="noreferrer">
              <img src="/images/playStoreMobile.svg" alt="" />
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
